import React from 'react';
import PageTitle from '../components/ui/PageTitle';

export default function AboutUs() {
     return (
          <>
               <PageTitle
                    firstWord='About Us'
                    secondWord='Ripout Rentals'
                    breadcrumbLink='About Us'
               />
               <div className='container text-center'>
                    <div class='max-w-7xl mx-auto'>
                         <p className='text-xl  !leading-10 mb-6 tablet:my-10'>
                              <span className='italic mr-1 text-yellow font-bold'>
                                   Ripout Rentals™
                              </span>{' '}
                              is the first company to rent out equipment
                              packages for{' '}
                              <span className='text-yellow font-bold'>
                                   dust free
                              </span>{' '}
                              tile removal. Operating in the greater Phoenix
                              Arizona area, we are able to provide our clients
                              with the latest equipment and tools available for
                              dustless tile removal in Phoenix Arizona and the
                              surrounding cities.
                         </p>
                         <p className='text-xl  !leading-10 mb-6 tablet:my-10'>
                              The staff has extensive knowledge of the equipment
                              we rent. If you have questions about what
                              equipment you may need for your project, don’t
                              hesitate to ask, we are here to help you complete
                              your tile demolition in a timely manner.
                         </p>
                         <p className='text-xl  !leading-10 mb-6 tablet:my-10'>
                              Our tile removal equipment rentals are the most
                              innovative tools available. We make equipment
                              available to, you, tile contractors, general
                              contractors and DIY homeowners. Our tool rentals
                              are so intuitive, special training is not
                              required. If you have removed tile in the Phoenix,
                              AZ area before, you know how difficult it is to
                              remove tile and thinset from a concrete slab. As a
                              result, renting our equipment guarantees the best
                              success possible.
                         </p>
                    </div>
               </div>
          </>
     );
}
