import React from 'react';

export default function PageTitle({ firstWord, secondWord, breadcrumbLink }) {
     return (
          <section id='pageTitle' className='bg-darker w-full'>
               <div className='container p-6 flex flex-col tablet:flex-row items-center justify-between'>
                    <h1 className='origin-left text-white uppercase font-bold text-[38px] leading-[2rem] whitespace-nowrap'>
                         <span className='block text-[20px] text-yellow'>
                              {firstWord}
                         </span>
                         {secondWord}
                    </h1>
                    <ul className='flex gap-6 text-white/40 hover:text-white duration-300 mt-6 text-sm laptop:text-lg'>
                         <li>
                              <a
                                   className='hover:text-white duration-300'
                                   href='/'
                                   title='Home'
                              >
                                   Home
                              </a>
                         </li>
                         <span className='text-yellow'>/</span>
                         <li className='text-white'>{breadcrumbLink}</li>
                    </ul>
               </div>
          </section>
     );
}
