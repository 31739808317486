import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './fonts/GeogrotesqueCyr-Bold.ttf';
import { BrowserRouter } from 'react-router-dom';
import AnalyticsScripts from './components/AnalyticsScripts';
import Header from './components/Header';
import Footer from './components/Footer';
ReactDOM.render(
     <React.StrictMode>
          <BrowserRouter>
               <AnalyticsScripts />
               <Header />
               <main>
                    <App />
               </main>
               <Footer />
          </BrowserRouter>
     </React.StrictMode>,
     document.getElementById('root')
);
