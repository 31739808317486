import React from 'react';
import PageTitle from '../components/ui/PageTitle';

export default function Schedule() {
     return (
          <>
               <PageTitle
                    firstWord='Schedule'
                    secondWord='Your Rental'
                    breadcrumbLink='Schedule'
               />
               <div className='container text-center'>
                    <rentle-store
                         shop='ripoutrentals'
                         product='B6ScM44Cd0rAYKbdSUiq'
                         disableautoscroll='true'
                         disableheightanimation='true'
                    />
               </div>
          </>
     );
}
