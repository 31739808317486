import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from './ui/Button';
import Logo from './ui/Logo';
import NavPrimary from './ui/NavPrimary';

const today = new Date();

export default function Footer() {
     return (
          <footer className=' bg-dark text-white text-center tablet:text-left'>
               <div className='container grid grid-cols-12 py-10'>
                    <div className='hidden tablet:flex flex-col tablet:col-span-5'>
                         <NavLink to='/' className='logo w-44 block mt-auto'>
                              <Logo />
                         </NavLink>
                         <NavPrimary className='flex flex-col laptop:flex-row gap-4 mt-12 mb-auto' />
                    </div>

                    <div className='text-white col-span-12 tablet:col-span-7'>
                         <h5 className='text-3xl text-yellow mb-6'>About Us</h5>
                         <p className='mb-12'>
                              Ripout Rentals™ is the first company to rent
                              out equipment packages for
                              <span className='text-yellow px-1'>
                                   dust free
                              </span>
                              tile removal. Operating in the greater Phoenix
                              Arizona area, we are able to provide our clients
                              with the latest equipment and tools available for
                              dustless tile removal in Phoenix Arizona and the
                              surrounding cities. Ripout Rentals™ is a division of <a href="https://www.floordozers.com">Floor Dozers®</a>
                         </p>
                         <Button
                              className='mt-2 tablet:mt-14 laptop:mt-20 mx-auto tablet:ml-[unset]'
                              Href='/about-us'
                              Text='Read More'
                              TextColor='text-white'
                         />
                    </div>
               </div>
               <div className='bg-darker'>
                    <p className='text-sm text-white text-center py-3'>
                         Ripout Rentals - Tile Removal Equipment Rentals
                         <span className='block tablet:inline-block tablet:ml-2'>
                              © {today.getFullYear()} - All Rights Reserved
                         </span>
                    </p>
               </div>
          </footer>
     );
}
