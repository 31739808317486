import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Faq from './pages/Faq';
import Pricing from './pages/Pricing';
import Schedule from './pages/Schedule';
import { Routes, Route } from 'react-router-dom';
function App() {
     return (
          <Routes>
               <Route path='/' element={<Home />} />
               <Route path='/about-us' element={<AboutUs />} />
               <Route path='/faq' element={<Faq />} />
               <Route path='/pricing' element={<Pricing />} />
               <Route path='/schedule' element={<Schedule />} />
          </Routes>
     );
}

export default App;
