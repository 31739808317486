import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavPrimary({ className }) {
     return (
          <ul
               className={`tracking-tighter laptop:text-xl text-white font-semibold ${className}`}
          >
               <li>
                    <NavLink to='/'>Home</NavLink>
               </li>
               <li>
                    <NavLink to='/about-us'>About Us</NavLink>
               </li>
               <li>
                    <NavLink to='/faq'>FAQ</NavLink>
               </li>
               <li>
                    <NavLink to='/pricing'>Pricing</NavLink>
               </li>
          </ul>
     );
}
