import React from 'react';
import Hero from '../components/Hero';
import Rental from '../components/Rental';
import Trailer from '../components/Trailer';

export default function Home() {
     return (
          <>
               <Hero />
               <Trailer />
               <Rental />
          </>
     );
}
