import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CgMenuRight } from 'react-icons/cg';
import { NavLink } from 'react-router-dom';

export default function NavMobile() {
     return (
          <div className='tablet:hidden absolute top-[58px] w-full px-8 text-right z-50'>
               <Menu as='div' className='relative inline-block text-left'>
                    <Menu.Button className='focus:outline-none -translate-y-3'>
                         <CgMenuRight
                              className='h-7 w-7 text-white'
                              aria-hidden='true'
                         />
                    </Menu.Button>
                    <Transition
                         as={Fragment}
                         enter='transition ease-out duration-100'
                         enterFrom='transform opacity-0 scale-95'
                         enterTo='transform opacity-100 scale-100'
                         leave='transition ease-in duration-75'
                         leaveFrom='transform opacity-100 scale-100'
                         leaveTo='transform opacity-0 scale-95'
                    >
                         <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-white rounded-b-xl bg-dark shadow-lg focus:outline-none'>
                              <div className='px-1 py-1 '>
                                   <Menu.Item>
                                        <NavLink
                                             to='/'
                                             className='text-white group flex w-full items-center rounded-lg p-3 text-lg hover:bg-darker/50 hover:text-yellow duration-300'
                                        >
                                             Home
                                        </NavLink>
                                   </Menu.Item>
                                   <Menu.Item>
                                        <NavLink
                                             to='/about-us'
                                             className='text-white group flex w-full items-center rounded-lg p-3 text-lg hover:bg-darker/50 hover:text-yellow duration-300'
                                        >
                                             About Us
                                        </NavLink>
                                   </Menu.Item>
                                   <Menu.Item>
                                        <NavLink
                                             to='/faq'
                                             className='text-white group flex w-full items-center rounded-lg p-3 text-lg hover:bg-darker/50 hover:text-yellow duration-300'
                                        >
                                             FAQ
                                        </NavLink>
                                   </Menu.Item>
                                   <Menu.Item>
                                        <NavLink
                                             to='/pricing'
                                             className='text-white group flex w-full items-center rounded-lg p-3 text-lg hover:bg-darker/50 hover:text-yellow duration-300'
                                        >
                                             Pricing
                                        </NavLink>
                                   </Menu.Item>
                                   <Menu.Item>
                                        <NavLink
                                             to='/schedule'
                                             className='text-white group flex w-full items-center rounded-lg p-3 text-lg hover:bg-darker/50 hover:text-yellow duration-300'
                                        >
                                             Schedule
                                        </NavLink>
                                   </Menu.Item>
                              </div>
                         </Menu.Items>
                    </Transition>
               </Menu>
          </div>
     );
}
