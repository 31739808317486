import React from 'react';
import Button from './ui/Button';
import {WistiaProvider, WistiaPlayer} from '@wistia/react-embeds';

export default function Rental() {
     return (
          <div className='max-w-[1440px] mx-auto px-8'>
               <div className='container px-0 rounded-3xl overflow-hidden grid grid-cols-12 my-10 laptop:my-16'>
                    <div className='col-span-12 tablet:col-span-6 bg-dark bg-cover bg-center relative min-h-[20rem] tablet:min-h-[25rem] laptop:min-h-[30rem]'>
                         <WistiaProvider>
                              <WistiaPlayer hashedId="71u4d274u2"></WistiaPlayer>
                         </WistiaProvider>
                    </div>
                    <div className='col-span-12 tablet:col-span-6 bg-dark flex'>
                         <div className='p-6 laptop:p-8 my-auto'>
                              <h2 className='text-white text-[50px] laptop:text-[60px] leading-[3rem]'>
                                   <span className='block text-[30px] text-yellow'>
                                        Ready to book?
                                   </span>
                                   Dustless Tile Removal Rental
                              </h2>
                              <p className='text-[25px] text-yellow mt-8'>
                                   You’re only one step away!
                              </p>
                              <span className='block text-[30px] text-white'>
                                        (602) 362-4644
                                   </span>
                              <Button
                                   className='mt-8 tablet:mt-14 laptop:mt-20'
                                   Href='/schedule'
                                   Text='Book Now'
                                   TextColor='text-white'
                              />
                         </div>
                    </div>
               </div>
          </div>
     );
}
