import React from 'react';

export default function Button({ Href, Text, TextColor, className }) {
     return (
          <a
               href={Href}
               className={`group relative font-semibold text-xl rounded-xl border-[1px] border-yellow text-white pt-4 pb-3.5 w-[150px] h-10 flex justify-center items-center ${className}`}
          >
               {' '}
               <span className='text-yellow pr-1 -mt-0.5 group-hover:tracking-[0.15em] absolute left-4 duration-300'>
                    {'//'}
               </span>
               <span
                    className={`pl-3 group-hover:pl-4 duration-300 ${TextColor}`}
               >
                    {Text}
               </span>
          </a>
     );
}
