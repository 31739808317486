export default function Logo(props) {
     return (
          <>
               <svg
                    id='Logo'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 128.21 37.42'
                    {...props}
                    className={`${props.className}`}
               >
                    <path
                         className='fill-yellow'
                         d='M0,11.95l8.61,13.06H0V11.95ZM107.29,6.98h-4.4v9.36c0,2.56-2.08,4.64-4.63,4.64s-4.64-2.08-4.64-4.64V6.98h-4.3l-.02,10.51c.58,4.49,4.43,7.88,8.96,7.88,4.98,0,9.04-4.05,9.04-9.04v-4.68h7.39v13.14h4.67V11.65h8.86V6.98h-20.92Zm-20.45,8.79c0,5.3-4.72,9.61-10.53,9.61s-10.53-4.31-10.53-9.61,4.72-9.61,10.53-9.61,10.53,4.31,10.53,9.61Zm-4.17,0c0-3-2.85-5.44-6.36-5.44s-6.36,2.44-6.36,5.44,2.85,5.44,6.36,5.44,6.36-2.44,6.36-5.44Z'
                    />
                    <path
                         className='fill-white'
                         d='M22.01,15.76l5.96,9.12h-5.49L13.54,11.29h7.06c1.85,0,3.35-1.5,3.35-3.35s-1.5-3.35-3.35-3.35H4.95l13.25,20.28h-5.49L.02,5.46V0H20.6c4.38,0,7.95,3.56,7.95,7.95,0,3.85-2.78,7.14-6.54,7.81Zm9.71,9.12h4.67V6.98h-4.67V24.88Zm0-20.25h4.67V.02h-4.67V4.62Zm30.29,11.39c0,4.53-3.39,8.38-7.88,8.96l-9.29-.02v5.6h-4.67V6.98h12.81c4.98,0,9.04,4.05,9.04,9.04Zm-4.4,0c0-2.56-2.08-4.63-4.64-4.63h-8.14v9.27h8.14c2.56,0,4.64-2.08,4.64-4.64Zm13.15,18.16l2.46,3.16h-.82l-2.42-3.11h-2.67v3.11h-.69v-7.43h3.52c1.72,0,2.96,.91,2.96,2.17,0,1.07-.95,1.91-2.33,2.11Zm-3.46-.54h2.81c1.32,0,2.28-.66,2.28-1.58s-.96-1.58-2.28-1.58h-2.81v3.16Zm8.44,.1h4.24v-.57h-4.24v-2.69h4.58v-.58h-5.27v7.43h5.42v-.58h-4.73v-3.01Zm13.16,2.53l-6.01-6.33-.04-.04h-.58v7.43h.69v-6.39l6.03,6.35,.04,.04h.56v-7.43h-.69v6.37Zm8.93-6.37h-6.78v.58h3.04v6.86h.69v-6.86h3.04v-.58Zm4.06,.07l4.17,7.37h-.77l-1.17-2.08h-4.98l-1.15,2.08h-.76l4.1-7.43h.53l.04,.07Zm-.29,.81l-2.15,3.9h4.35l-2.21-3.9Zm6.57-.88h-.69v7.43h5.25v-.58h-4.56v-6.86Zm8.62,3.33c-1.32-.22-2.57-.43-2.57-1.41,0-.87,.95-1.43,2.43-1.43s2.35,.61,2.35,1.54v.13h.69v-.13c0-1.29-1.19-2.13-3.03-2.13s-3.13,.79-3.13,2.02c0,1.45,1.65,1.72,3.13,1.97,1.38,.24,2.58,.44,2.58,1.46,0,.92-1.03,1.56-2.51,1.56-1.64,0-2.7-.68-2.7-1.74v-.13h-.7v.13c0,1.39,1.37,2.32,3.4,2.32,1.92,0,3.21-.86,3.21-2.15,0-1.5-1.66-1.78-3.14-2.04Z'
                    />
               </svg>
          </>
     );
}
