import React from 'react';
import Button from '../components/ui/Button';
import PageTitle from '../components/ui/PageTitle';

export default function Pricing() {
     return (
          <>
               <PageTitle
                    firstWord='Rental'
                    secondWord='Pricing'
                    breadcrumbLink='Pricing'
               />
               <div className='mx-auto max-w-7xl px-6 py-12 laptop:py-40 laptop:px-8'>
                    <div className='laptop:grid laptop:grid-cols-12 laptop:gap-8 py-12'>
                         <div className='laptop:col-span-5'>
                              <div className='sticky top-32'>
                                   <h2 className='origin-left text-dark uppercase font-bold text-[40px] leading-[2.5rem] mb-3'>
                                        <span className='block text-[30px] text-yellow'>
                                             How much does
                                        </span>
                                        <span className='italic mr-2'>
                                             dustless
                                        </span>{' '}
                                        tile removal
                                        <span className='block text-[30px] text-yellow'>
                                             Cost in Arizona?
                                        </span>
                                   </h2>
                              </div>
                         </div>
                         <div className='mt-10 laptop:col-span-7 laptop:mt-0'>
                              <p className='py-4'>
                                   First off, there is a big difference between
                                   the regular, old, tile removal and a dustless
                                   tile removal method. Homeowners who have had
                                   tile removed in the past understand the dusty
                                   mess that goes along with traditional tile
                                   removal, let’s just agree, you don’t want
                                   that!
                              </p>
                              <p className='py-4'>
                                   As is the case with all things superior,
                                   there is an upfront price increase associated
                                   with dustless tile removal. However, you will
                                   appreciate the savings in time and cost
                                   overall. Rather, consider the savings of
                                   hiring a professional cleaning company,
                                   sometimes 3-4 cleanings will be required
                                   after a small tile removal project, and often
                                   that will not be enough. Dust from
                                   traditional tile demolition will creep into
                                   every bit of open, or slightly open, space.
                                   Dust will be in all your cabinets if not
                                   sealed properly with plastic.
                              </p>
                              <p className='py-4'>
                                   <span className='italic mr-1'>
                                        Ripout Rentals™
                                   </span>{' '}
                                   provides top notch tile removal equipment for
                                   rent. We are the first to offer, as rentals,
                                   key components of the dustless removal
                                   process. Many of our tools are not available
                                   anywhere else as rentals.
                              </p>
                         </div>
                    </div>

                    <div className='laptop:grid laptop:grid-cols-12 laptop:gap-8 py-12'>
                         <div className='laptop:col-span-5'>
                              <div className='sticky top-32'>
                                   <h2 className='origin-left text-dark uppercase font-bold text-[40px] leading-[2.5rem] mb-3'>
                                        <span className='block text-[30px] text-yellow'>
                                             Tile Removal:
                                        </span>
                                        The Difference in Cost.
                                   </h2>
                              </div>
                         </div>
                         <div className='mt-10 laptop:col-span-7 laptop:mt-0'>
                              <p className='py-4'>
                                   Traditional tile demo cost in the greater
                                   Phoenix area can be as low as $1.50 per
                                   square foot (porcelain or ceramic). But, keep
                                   in mind the additional cost of cleaning and
                                   the excessive time involved with the clean
                                   up. In general, contractors offering
                                   traditional tile demolition will not include
                                   much clean up in their price.
                              </p>
                              <p className='py-4'>
                                   A true dustless tile removal contractor will
                                   know the price should be around $3-$3.50 per
                                   square foot for a porcelain or ceramic
                                   removal. There should be $0 in clean up cost
                                   if you hire a true dustless tile removal
                                   contractor.
                              </p>
                              <p className='py-4'>
                                   Either removal process has disposal fees
                                   associated with it. As a result this cost is
                                   a wash.
                              </p>
                         </div>
                    </div>

                    <div className='laptop:grid laptop:grid-cols-12 laptop:gap-8 py-12'>
                         <div className='laptop:col-span-5'>
                              <div className='sticky top-32'>
                                   <h2 className='origin-left text-dark uppercase font-bold text-[40px] leading-[2.5rem] mb-3'>
                                        <span className='block text-[30px] text-yellow'>
                                             Ripout Rental™:
                                        </span>
                                        Cost of Rental
                                   </h2>
                              </div>
                         </div>
                         <div className='mt-10 laptop:col-span-7 laptop:mt-0'>
                              <p className='py-4'>
                                   The cost to rent one of our trailers per day
                                   is $650.00. This includes everything you will
                                   need to remove your tile, thinset and tools
                                   to load everything out of your house.
                              </p>
                              <p className='py-4'>
                                   Homeowners, ask your tile contractor if they
                                   will be providing a dustless tile demolition!
                                   If not, have them contact us, or contact us
                                   yourself. Many contractors will be renting
                                   cheap demolition tools to accomplish your
                                   tile demolition, consequently, the job takes
                                   longer as well as inadequately remove the
                                   thinset. Most are happy to rent our equipment
                                   for a superior, lasting, new tile
                                   installation.
                              </p>
                              <p className='py-4'>
                                   Schedule your rental today.
                              </p>
                              <Button
                                   className='hidden tablet:flex mt-12'
                                   Href='/schedule'
                                   Text='Book Now'
                                   TextColor='text-dark'
                              />
                         </div>
                    </div>
               </div>
          </>
     );
}
