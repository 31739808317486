import React from 'react';
import bgGrunge from '../assets/bg-grunge.jpg';
import hepaVacuums from '../assets/img-hepa_vacuums.png';
import tileBreakers from '../assets/img-tile_breakers.png';
import Grinders from '../assets/img-grinders.png';
import debrisCollection from '../assets/img-debris_collection.png';
import { GiCheckMark } from 'react-icons/gi';

export default function Trailer() {
     return (
          <div className='container'>
               <div className='mx-auto py-12 text-center max-w-5xl'>
                    <h2 className='text-center text-dark text-[60px] leading-[3rem] tracking-tight mb-3 whitespace-nowrap'>
                         <span className='block text-[30px] text-yellow'>
                              What's Included
                         </span>
                         In The Trailer
                    </h2>
                    <p>
                         Our equipment list includes everything you may need to
                         remove porcelain, ceramic, saltillo, stone and thinset
                         from concrete. When you rent one of our Tile Removal
                         trailers you will quickly find that you have what you
                         need.
                    </p>
               </div>
               <div className='group grid grid-cols-12 gap-4 mb-4'>
                    <div className='group-hover:opacity-95 hover:opacity-100 col-span-12 tablet:col-span-6 relative rounded-3xl overflow-hidden bg-dark h-[35rem] p-4 laptop:p-6 laptop-l:p-8 max-w-[352px] mx-auto tablet:mr-[unset] tablet:ml-auto laptop:mx-auto laptop:max-w-none laptop:!m-0 laptop:h-[400px]'>
                         <div className='copy z-20 relative laptop:w-[65%]'>
                              <h2 className='text-left text-dark uppercase font-bold text-[46px] leading-[2.4rem] mb-3 whitespace-nowrap'>
                                   <span className='block text-[45px] laptop:text-[35px] text-yellow'>
                                        High-Powered
                                   </span>
                                   <span className='block text-[35px] laptop:text-[27px] text-yellow'>
                                        Commercial Grade
                                   </span>
                                   HEPA Vacuums
                              </h2>
                              <ul className='my-6'>
                                   <li>
                                        <GiCheckMark className='fill-yellow inline mr-2 my-2' />
                                        Conform to new OSHA silica rules
                                   </li>
                                   <li>
                                        <GiCheckMark className='fill-yellow inline mr-2 my-2' />
                                        Secure dangerous silica dust safely in
                                        our Longopac® bagging system
                                   </li>
                              </ul>
                              <img
                                   className='laptop:absolute laptop:z-10 laptop:-right-[17rem] top-4'
                                   src={hepaVacuums}
                                   alt='HEPA Vacuums'
                              />
                         </div>
                         <img
                              className='absolute inset left-0 top-0 w-full h-full z-10'
                              src={bgGrunge}
                              alt='Background'
                         />
                    </div>
                    <div className='group-hover:opacity-95 hover:opacity-100 col-span-12 tablet:col-span-6 relative rounded-3xl overflow-hidden bg-dark p-4 laptop:p-6 laptop-l:p-8 max-w-[352px] mx-auto tablet:ml-[unset] laptop:mx-auto laptop:max-w-none laptop:h-[400px]'>
                         <div className='copy z-20 relative laptop:w-[75%]'>
                              <h2 className='text-right laptop:text-left text-dark uppercase font-bold text-[43px] leading-[2.4rem] mb-3 whitespace-nowrap'>
                                   <span className='block text-[24px] text-yellow'>
                                        Demolition Hammers &
                                   </span>
                                   Tile Breakers
                              </h2>
                              <p className='mt-6 mb-20 pl-14 text-right laptop:text-left laptop:pl-0 laptop:mb-6'>
                                   We provide a variety of demolition hammers,
                                   chisels and dust extraction attachments to
                                   increase production and allow the dustless
                                   removal of tile.
                              </p>

                              <p>
                                   We provide a specialty “stand up” demolition
                                   hammer not available from any other
                                   tool-equipment rental company, designed
                                   specifically for fast, easier, tile removal.
                              </p>
                              <img
                                   className='absolute inset -top-8 -left-48 laptop:left-[unset] laptop:-right-[11rem] laptop:h-[390px]'
                                   src={tileBreakers}
                                   alt='Tile Breakers'
                              />
                         </div>
                         <img
                              className='absolute inset left-0 top-0 w-full h-full z-10'
                              src={bgGrunge}
                              alt='Background'
                         />
                    </div>
                    <div className='group-hover:opacity-95 hover:opacity-100 col-span-12 tablet:col-span-6 relative rounded-3xl overflow-hidden h-[35rem] bg-dark p-4 laptop:p-6 laptop-l:p-8 max-w-[352px] mx-auto tablet:mr-[unset] laptop:mx-auto laptop:max-w-none laptop:h-[400px]'>
                         <div className='copy z-20 relative h-full laptop:w-[65%]'>
                              <h2 className='text-left text-dark uppercase font-bold text-[55px] leading-[2.4rem] mb-3 whitespace-nowrap'>
                                   <span className='block text-[28px] text-yellow'>
                                        Stand Up
                                   </span>
                                   Grinders
                              </h2>
                              <p className='mt-6'>
                                   Our trailer rentals include a “stand up”
                                   grinder with diamond segments. While other
                                   non specialized equipment rental companies
                                   will make you purchase the very expensive
                                   diamond segments as a hidden cost. Please
                                   note, our rentals include the “wear and tear”
                                   on our diamond segments.
                              </p>
                              <img
                                   className='absolute inset bottom-8 -right-36 -scale-x-150 scale-150 laptop:-right-[22rem] laptop:-bottom-[3rem] laptop:scale-100 laptop:min-w-[500px]'
                                   src={Grinders}
                                   alt='Grinders'
                              />
                         </div>
                         <img
                              className='absolute inset left-0 top-0 w-full h-full z-10'
                              src={bgGrunge}
                              alt='Background'
                         />
                    </div>
                    <div className='group-hover:opacity-95 hover:opacity-100 col-span-12 tablet:col-span-6 relative rounded-3xl overflow-hidden bg-dark p-4 laptop:p-6 laptop-l:p-8 max-w-[352px] mx-auto tablet:ml-[unset] laptop:mx-auto laptop:max-w-none laptop:h-[400px]'>
                         <div className='copy z-20 relative laptop:w-[65%]'>
                              <img
                                   className='-mt-60 tablet:-mt-[22rem] laptop:absolute laptop:-right-[20rem] laptop:-bottom-[4rem]'
                                   src={debrisCollection}
                                   alt='Debris Collection'
                              />
                              <h2 className='text-left text-dark uppercase font-bold text-[46px] leading-[2.4rem] mb-3 whitespace-nowrap'>
                                   <span className='block text-[45px] text-yellow'>
                                        Debris
                                   </span>
                                   Collection
                              </h2>
                              <p className='mt-6'>
                                   We include debris collection systems that
                                   attach to the provided vacuums.{' '}
                                   <span className='font-bold'>
                                        NOT included
                                   </span>{' '}
                                   in the rental is disposal. We recommend the
                                   following services for disposal:
                              </p>
                              <ul className='my-6'>
                                   <li>
                                        <GiCheckMark className='fill-yellow inline mr-2 my-2' />
                                        Bin There Dump That™
                                   </li>
                                   <li>
                                        <GiCheckMark className='fill-yellow inline mr-2 my-2' />
                                        The Bagster®
                                   </li>
                              </ul>
                         </div>
                         <img
                              className='absolute inset left-0 top-0 w-full h-full z-10'
                              src={bgGrunge}
                              alt='Background'
                         />
                    </div>
               </div>
          </div>
     );
}
