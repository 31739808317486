import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './ui/Logo';
import Button from './ui/Button';
import NavMobile from './ui/NavMobile';
import NavPrimary from './ui/NavPrimary';
export default function Header() {
     return (
          <>
               <header className='bg-dark w-full sticky top-0 left-0 z-50'>
                    <div className='h-[20px] bg-darker'></div>
                    <div className='h-[80px] container max-w-[1440px] flex items-center justify-center'>
                         <NavLink
                              to='/'
                              className='logo z-50 w-36 laptop-l:w-44 mr-auto tablet:mr-0 tablet:inline-block'
                         >
                              <Logo />
                         </NavLink>
                         <NavPrimary className='hidden z-50 tablet:flex tablet:mx-auto items-center gap-4 laptop:gap-8 desktop:gap-8' />
                         <NavMobile />
                         <Button
                              className='hidden tablet:flex'
                              Href='/schedule'
                              Text='Book Now'
                              TextColor='text-white'
                         />
                         <div className='bg-darker absolute left-0 top-[20px] h-[80px] w-3/4 tablet:w-[30%] laptop:w-[25%] laptop-l:w-[30%] clip z-10'></div>
                    </div>
               </header>
          </>
     );
}
